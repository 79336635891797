import store from '@/store';
import { updateSiemprePlusData, setUpdated } from '@/actions/siemprePlus';
import { setUserAuthToken } from 'js-api-client';
import Cookies from 'js-cookie';
import growthBook from '../../services/growthBook/GrowthBook';
import { setAlert } from '../../actions';
import { resetCardsState } from '../../actions/cards';

const SESSION_FLAG = 'FUNNEL-LOGGED';
/**
 * Update siemprePlus data in the store
 * @param {Object} param0
 * @param {Object} param0.user - User data
 */
const handleDotersOrSiemprePlusProfileUpdate = ({ user, loyaltyName }) => {
  const {
    siemprePlus,
    whitelabelConfig: { env },
  } = store.getState();
  const { logged, requested } = siemprePlus.toJS();

  if (user) {
    setUserAuthToken(user.token);
    const loggedSession = sessionStorage.getItem(SESSION_FLAG);
    if (!logged && !loggedSession) {
      sessionStorage.setItem(SESSION_FLAG, true);
      store.dispatch(setAlert('logged_in', 'success'));
    }
  } else {
    /**
     * If the user is not logged in, we need to remove the token from the API client
     * First validate if requested and no user is present.
     * Related issues: https://reservamossaas.atlassian.net/browse/EN-875 and https://reservamossaas.atlassian.net/browse/EN-1580
     */
    if (requested) {
      setUserAuthToken(undefined);
    }
    store.dispatch(resetCardsState());
  }
  store.dispatch(updateSiemprePlusData(user, loyaltyName));
  store.dispatch(setUpdated(!!user));
  if (!Cookies.get('token')) sessionStorage.removeItem(SESSION_FLAG);

  // Set GrowthBook user attributes
  if (env.growthBook)
    growthBook.setAttributes({
      logged: !!user,
    });
};

export default handleDotersOrSiemprePlusProfileUpdate;
