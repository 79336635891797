import { Map } from 'immutable';
import { setConfig as setApiConfig } from 'js-api-client';
import { setUserProperties } from 'user-analytics';
import { UPDATE_SIEMPRE_PLUS_DATA, SET_UPDATED_SIEMPRE_PLUS } from 'constants/ActionTypes';
import Cookies from 'js-cookie';

const defaultState = Map({
  user: null,
  isUpdated: false,
  requested: false,
  logged: false,
});

export default function siemprePlus(state = defaultState, action) {
  const isUpdated = state.get('isUpdated');
  const cookieToken = Cookies.get('token');

  switch (action.type) {
    case UPDATE_SIEMPRE_PLUS_DATA:
      if ((!action.user || !action.user.email) && !cookieToken) {
        setApiConfig({ userAuthToken: undefined });
        setUserProperties({ 'User Type': 'Anonymous' });
        return state.set('user', null).set('isUpdated', false).set('requested', true);
      }
      if (isUpdated) return state;

      if (action.user) {
        setApiConfig({ userAuthToken: action.user.token });
        setUserProperties({ 'User Type': 'Registered' });
      }

      return state
        .set('user', action.user ? Map({ ...action.user, loyaltyName: action.loyaltyName }) : null)
        .set('requested', !action.user ? !cookieToken : true)
        .set('logged', action?.user && true);

    case SET_UPDATED_SIEMPRE_PLUS:
      return state.set('isUpdated', action.isUpdated).set('requested', true);

    default:
      return state;
  }
}
